import {
	ref,
	computed,
} from 'vue';

import { INSTAGRAM_MEDIA_PLACEHOLDERS } from '@zyro-inc/site-modules/constants/instagramPlaceholders';

const INSTAGRAM_API_URL = 'https://graph.instagram.com/me/media';
const INSTAGRAM_API_FIELDS = [
	'id',
	'permalink',
	'caption',
	'media_url',
	'thumbnail_url',
];
const INSTAGRAM_API_VERSION = {
	INSTAGRAM_BASIC_DISPLAY: 'instagram_basic_display',
	INSTAGRAM_API_WITH_INSTAGRAM_LOGIN: 'instagram_api_with_instagram_login',
};

export const useGridInstagramFeed = ({
	elementId,
	elementData,
	siteId,
	getInstagramTokenHandler,
	getInstagramFeedHandler,
	cleanupCallback,
}) => {
	const limit = computed(() => elementData.value.settings['item-count']);
	const token = ref(null);
	const responseResult = ref([]);

	const isNewInstagramApi = computed(
		() => elementData.value.settings.apiVersion === INSTAGRAM_API_VERSION.INSTAGRAM_API_WITH_INSTAGRAM_LOGIN,
	);

	const media = computed(() => {
		if (responseResult.value.length > 0) {
			return responseResult.value
				.filter(({ src }) => typeof src !== 'undefined')
				.slice(0, limit.value);
		}

		return new Array(limit.value).fill({});
	});

	const getInstagramMedia = async () => {
		if (isNewInstagramApi.value) {
			return getInstagramFeedHandler({
				siteId,
				elementId: elementId.value,
			});
		}

		const apiUrl = new URL(INSTAGRAM_API_URL);

		apiUrl.searchParams.set('fields', INSTAGRAM_API_FIELDS.join(','));
		apiUrl.searchParams.set('access_token', token.value);

		const res = await fetch(apiUrl.toString());

		if (res.ok) {
			const data = await res.json();

			return data.data;
		}

		return Promise.reject(new Error('Failed to fetch Instagram media'));
	};

	const init = async () => {
		try {
			if (!isNewInstagramApi.value) {
				token.value = await getInstagramTokenHandler({
					siteId,
					elementId: elementId.value,
				});
			}
		} catch (error) {
			responseResult.value = INSTAGRAM_MEDIA_PLACEHOLDERS;

			return;
		}

		try {
			const data = await getInstagramMedia();

			responseResult.value = data.map((item) => ({
				id: item.id,
				src: item.media_url,
				alt: item.caption,
				href: item.permalink,
				poster: item.thumbnail_url,
			}));
		} catch (error) {
			responseResult.value = INSTAGRAM_MEDIA_PLACEHOLDERS;

			if (cleanupCallback) {
				cleanupCallback();
			}

			throw error;
		}
	};

	return {
		media,
		init,
		itemGap: computed(() => elementData.value.settings.styles['item-gap']),
	};
};

/**
 * TODO:
 * preconnect instagram to head
 * proper multi-step error handling
 * refactor overall logic - missign token should not fall back to unsplash by default
 */

<script setup lang="ts">
import { EcommerceProductAdditionalInfo } from '@zyro-inc/site-modules/types';
import { ref } from 'vue';

type Props = {
	productDescription: string;
	data: EcommerceProductAdditionalInfo[];
}

defineProps<Props>();

const activeTab = ref(0);
const infoButtonRef = ref<Record<number, any>>({});

const setActiveTab = (index: number) => {
	if (activeTab.value === index) {
		activeTab.value = -1;

		return;
	}

	activeTab.value = index;

	setTimeout(() => {
		infoButtonRef.value[index].scrollIntoView({
			behavior: 'smooth',
			block: 'center',
		});
	}, 200);
};

const unsetHeightForTransition = (el: any) => {
	// eslint-disable-next-line no-param-reassign
	el.style.height = '0';
};

const setScrollHeightForTransition = (el: any) => {
	// eslint-disable-next-line no-param-reassign
	el.style.height = `${el.scrollHeight}px`;

	// to prevent section height inconsistencies after viewport in editor change
	setTimeout(() => {
		// eslint-disable-next-line no-param-reassign
		el.style.height = '';
	}, 200);
};

</script>

<template>
	<section class="additional-info-sections">
		<div
			v-for="(info, index) in data"
			:id="info.id"
			:key="info.id"
			class="additional-info-sections__item"
		>
			<button
				:ref="(el) => (infoButtonRef[index] = el)"
				class="additional-info-sections__button"
				:aria-expanded="activeTab === index"
				:aria-controls="`additional-info-${info.id}`"
				@click="setActiveTab(index)"
			>
				<h2 class="additional-info-sections__item-title">
					{{ info.title }}
				</h2>
				<span
					class="additional-info-sections__icon"
					:class="{ 'additional-info-sections__icon--active': activeTab === index }"
				/>
			</button>
			<Transition
				name="accordion"
				@enter="setScrollHeightForTransition"
				@before-enter="unsetHeightForTransition"
				@before-leave="setScrollHeightForTransition"
				@leave="unsetHeightForTransition"
			>
				<p
					v-show="activeTab === index"
					:id="`additional-info-${info.id}`"
					class="additional-info-sections__item-description"
					v-html="info.description"
				/>
			</Transition>
		</div>
	</section>
</template>

<style lang="scss" scoped>
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";
@import "@zyro-inc/site-modules/components/blocks/ecommerce/-partials/shared";
@import "@zyro-inc/site-modules/scss/mixins/font-style";
@include font-style("h6", ".additional-info-sections__item-title", ".additional-info-sections");

.additional-info-sections {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 32px;

	&__item {
		background-color: transparent;
		position: relative;
		padding: 24px 0;
		text-align: left;
		cursor: pointer;

		&:not(:last-child) {
			border-bottom: 1px solid var(--body-color);
		}
	}

	&__button {
		display: flex;
		width: 100%;
		justify-content: space-between;
		font-size: 20px;
	}

	&__icon {
		$thickness: 1px;

		display:inline-block;
		width: 13px;
		height: 13px;
		background:
			linear-gradient(var(--body-color) 0 0) content-box,
			linear-gradient(var(--body-color) 0 0) content-box,
			transparent;
		background-position:center;
		background-size: 100% $thickness, $thickness 100%;
		background-repeat:no-repeat;

		&--active {
			background-size: 100% $thickness, 0;
		}
	}

	&__item-description {
		width: 100%;
		margin-top: 16px;
		word-break: break-word;
		white-space: pre-line;

		:deep() {
			@include description-style;
		}
	}
}

@include site-engine-mobile {
	.additional-info-sections {
		margin-top: 24px;
	}
}

.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}
</style>
